<template>
  <div>
    <div
      v-if="!isLoading"
      style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100vh;">
      <h2>{{ status == success ? $t('user.successfulActivation') : $t('user.failedActivation') }}</h2>
      <h3 v-if="status == success">{{ $t('user.redirectLoginPage') }}</h3>
    </div>
    <loading-bar :is-loading="isLoading" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ToastType from 'enum/toastType'
export default {
  data: () => ({
    message: '',
    redirectLoginText: '',
    success: ToastType.SUCCESS,
    error: ToastType.ERROR,
    status: null,
    isLoading: false
  }),
  created () {
    let params = this.$route.params
    let filter = {
      uid: params.uid,
      token: params.token
    }
    this.isLoading = true
    this.ACTIVATE_ACCOUNT(filter).then(
      function () {
        this.isLoading = false
        this.status = this.success
        this.onRedirectToLogin()
      }.bind(this)
    ).catch(
      function () {
        this.isLoading = false
        this.status = this.error
      }.bind(this)
    )
  },
  methods: {
    /**
     * Redirect to login
     */
    onRedirectToLogin: function () {
      setTimeout(() => {
        this.$router.push({
          path: '/login'
        })
      }, 5000)
    },
    ...mapActions([
      'ACTIVATE_ACCOUNT'
    ])
  }
}
</script>

<style>

</style>
